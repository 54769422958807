import { render, staticRenderFns } from "./ArchiveManage.vue?vue&type=template&id=df67864a&"
import script from "./ArchiveManage.vue?vue&type=script&lang=js&"
export * from "./ArchiveManage.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports